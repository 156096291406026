import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";

import { MuiProvider } from "./src/theme/provider";
import "./src/helpers/sprig";

Sentry.init({
  dsn: "https://94b0f285540dd56ee03bbf9eecb49c00@o1162039.ingest.us.sentry.io/4507612320366592",
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

startTransition(() => {
  hydrateRoot(
    document,
    <MuiProvider>
      <RemixBrowser />
    </MuiProvider>,
    {
      onRecoverableError: Sentry.reactErrorHandler(),
    }
  );
});
